import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Input, Modal, Row, Select, Checkbox, Divider, Spin } from "antd";
import { languageProficiency, validateMessages } from "../../utils/StaticDataUtility";
import { connect } from "react-redux";
import { getCadidateResume, applyJob, updateAboutProfile, updateJobApplication } from "../../services/common.service";
import { CustomeNotification } from "../../utils/NotificationUtility";
import "./ApplyJobModelPage.css";
import { sendCAQEMail } from '../../services/user.service';
import environment from "../../environments/environment";
import Title from "antd/es/typography/Title";
export interface IProps {
    isModalOpen: any,
    onCloseModal: () => void,
    type: string,
    title: string,
    user: any
    jobOrderId: any,
    selectedJob: any,
}

const ApplyJobModalPage: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    let { title, isModalOpen, user, jobOrderId, selectedJob } = props;
    const [loading, setLoading] = useState(false);
    const [resumeListOptions, setResumeListOptions] = useState([]);
    const [isCaqModel, setCaqModel] = useState(false);
    const [appicationSfdcId, setAppicationSfdcId] = useState({});
    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        props.onCloseModal();
    };
    const initialFormValues = {
    }
    useEffect(() => {
        if (user && user._id) {
            setFormValues(user);
            loadResumeList(user._id);
        }
    }, [user]);

    const loadResumeList = async (profileId: string) => {
        setLoading(true);
        const resumeList: any = await getCadidateResume(profileId);
        setLoading(false);
        if (resumeList) {
            setResumeListOptions(resumeList);
            if (resumeList[0] && resumeList[0]['value']) {
                form.setFieldsValue({ resume: resumeList[0]['value'] });
            }

        }
    }
    const setFormValues = (user: any) => {
        form.setFieldsValue({ full_name: user.full_name });
        form.setFieldsValue({ email: user.email });
        form.setFieldsValue({ phone: user.phone });
        form.setFieldsValue({ bgv: user.consent && user.consent.bgv ? user.consent.bgv : false });
        form.setFieldsValue({ drug_test: user.consent && user.consent.drug_test ? user.consent.drug_test : false });

    }

    const onFormFinish = async (values: any) => {
        let id = user._id || ''; //person_id
        let sfdcId = user.sfdc_id || '';
        let language = user.profile && user.profile.languages ? user.profile.languages : '';
        let profile = user.profile ? user.profile : '';
        let localLangugeProficiency: any = (language && language[0] && language[0]['fluency_level']) ? languageProficiency.find(i => i.value === language[0]['fluency_level']) : '';
        let englishLangugeProficiency: any = (language && language[1] && language[1]['fluency_level']) ? languageProficiency.find(i => i.value === language[1]['fluency_level']) : '';
        const applicationData = {
            applied_by_candidate: id,
            candidate_sfdcId: sfdcId,
            job_order_id: jobOrderId ? jobOrderId : 1,
            applied_on: new Date(),
            resume: values['resume'],
            applied_as_guest: (user) ? false : true,
            bgv_consent_given: new Date(),
            guest_full_name: values['full_name'],
            guest_email: values['email'],
            guest_phone: values['phone'],
            job_company_name: '', //selectedJob._source.account ? selectedJob._source.account.Name : '', 
            job_title: selectedJob._source.Job_Title__c,
            job_location: selectedJob._source.Location_List_New__c,
            job_exprince: '5 Years', // Add it Lated
            hiring_manager: selectedJob._source.Hiring_Manager__c,
            secondary_recruiter: selectedJob._source.Secondary_Recruiter__c,
            local_language: (language && language[0] && language[0]['title']) ? language[0]['title'] : '',
            local_language_proficiency: (localLangugeProficiency) ? localLangugeProficiency.label : '',
            english_language_proficiency: (englishLangugeProficiency) ? englishLangugeProficiency.label : '',
            job_order_number: selectedJob._source.Job_Number__c,
            caq_submitted: false,
            internal_question_set: selectedJob._source.Internal_Question_Set__c,
            about_you: profile['about_you']
        }
        updateConsent(id, values);
        createApplication(applicationData);
    };

    const updateConsent = (id: any, values: any) => {
        const consentArray = [];
        if (values['bgv']) {
            consentArray.push({ permission_type: 'BGV', last_permitted_on: new Date() })
        }
        if (values['drug_test']) {
            consentArray.push({ permission_type: 'DRUG_TEST', last_permitted_on: new Date() })
        }
        if (consentArray.length) {
            const profileData = {
                consent: consentArray
            }
            updateAboutProfile(id, profileData);
        }
    }

    const createApplication = (applicationData: any) => {
        setLoading(true);
        applyJob(applicationData).then((res: any) => {
            setLoading(false);
            if (res && res.sfdc_id) {
                console.log('Application Res::', res)
                CustomeNotification('success', `Job Applied Successfully.`);
                setAppicationSfdcId(res.sfdc_id || '')
                setCaqModel(true);
            } else {
                CustomeNotification('error', "Opps! Something went wrong please try again!");
            }
        }).catch(err => {
            setLoading(false);
        });
    }
    /**
        * On form submit failed
        * @param errorInfo 
        */
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const submitNow = () => {
        handleCancel();
        //redirect on caq pages.  
        const internalQuestionSet = selectedJob._source ? selectedJob._source.Internal_Question_Set__c : '';
        if (appicationSfdcId && internalQuestionSet) {
            navigate('/person-caq/' + appicationSfdcId + '/' + internalQuestionSet, { replace: true });
        } else {
            CustomeNotification('error', "Opps! Something went wrong please try again!");
        }
    }

    const submitLater = async () => {
        handleCancel();
        //send caq to candidate on their email id
        const selectedJobInfo = selectedJob._source;
        const url = environment.caqUrl + '/public/caq/' + appicationSfdcId + '/' + selectedJobInfo.Internal_Question_Set__c;
        const questionSet = selectedJobInfo.Internal_Question_Set__c ? selectedJobInfo.Internal_Question_Set__c : '';
        const queName = selectedJobInfo.internalQuestionSet && selectedJobInfo.internalQuestionSet.Name ? selectedJobInfo.internalQuestionSet.Name : '';
        const jobOrderNumber = selectedJobInfo.Job_Number__c ? selectedJobInfo.Job_Number__c : '';
        const jobTitle = selectedJobInfo.Job_Title__c ? selectedJobInfo.Job_Title__c : '';

        const dataObj = {
            appSfdcId: appicationSfdcId, url: url, queSet: questionSet,
            queName: queName,
            jobName: jobOrderNumber,
            jobTitle: jobTitle
        };

        const emailSend: any = await sendCAQEMail(dataObj);
        if (emailSend) {
            const data = {
                caq_submitted: false
            }
            updateJobApplication(data, appicationSfdcId);
            CustomeNotification('success', `Candidate assessment questionnaire email has been sent successfully.`);
        }
    }

    return (
        <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={500} footer={<small></small>}>
            <Spin spinning={loading} tip="Loading...">
                {isCaqModel
                    ?
                    <Fragment>
                        <Row>
                            <Col span={24} className="TextCenter">
                                <Title className="mt-1" level={4} style={{ color: '#d9363e' }}>Your candidate application is incomplete!</Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="TextCenter">
                                <Title className="mt-0" level={5} style={{ color: 'rgb(100, 116, 139)', }}>Please fill in and submit the assessment questionnaire to complete your application and increase your chance of getting hired.</Title>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24}>
                                <Button key="submitCAQNow" type="primary" className="mr-5 w-100 text-wrap" onClick={submitNow}>Submit Assessment Questionnaire Now </Button>
                                <Button className="w-100 mt-3" key="submitCAQLater" onClick={submitLater}>Submit Later</Button>
                            </Col>
                        </Row>
                    </Fragment>
                    :
                    <Form layout="vertical"
                        initialValues={initialFormValues}
                        autoComplete="off"
                        form={form}
                        validateMessages={validateMessages}
                        onFinish={onFormFinish}
                        className="apply-form"
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item label="Resume" name="resume" rules={[{ required: true }]}>
                                    <Select defaultValue={resumeListOptions[0]} options={resumeListOptions} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Name" name="full_name" rules={[{ required: true, max: 100 }]}>
                                    <Input disabled={true} key='userName' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Email" name="email" rules={[{ required: true, max: 100 }]}>
                                    <Input disabled={true} key='userEmail' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Phone" name="phone" rules={[{ required: true, max: 100 }]}>
                                    <Input disabled={true} key='userPhone' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="bgv" valuePropName="checked" rules={[{ required: true }]}>
                                    <Checkbox>BGV</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item name="drug_test" valuePropName="checked" rules={[{ required: true }]}>
                                    <Checkbox>DRUG TEST</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col span={24} className="TextRight">
                                <Button key="cancelApply" className="mr-5" loading={loading} onClick={handleCancel}>CANCEL </Button>
                                <Button key="submitapply" type="primary" loading={loading} onClick={handleOk}>APPLY</Button>
                            </Col>
                        </Row>

                    </Form>
                }
            </Spin>
        </Modal>
    );
};


const mapStateToProps = (state: any) => {
    return {
        user: state.reducer.auth.user,
        selectedJob: state.reducer.job.selectedJob
    }
};

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyJobModalPage)
