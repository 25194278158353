import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, Col, Input, Typography, Row, Select, Divider, Spin } from "antd";
import SaveJobsCard from "../components/shared/saved-jobs-card/SaveJobsCard";

const { Title, Text } = Typography;

export interface pageProps {

}

const SavedJobsPage: React.FC<pageProps> = (props) => {

    return (
        <>
            <Row className="homeHeaderBg">
                <Col span={24}><h2 className="text-center text-white">&nbsp;</h2></Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={22} sm={22} md={24} lg={16} className="mb-10 white-container savedJobsContainer">
                    <SaveJobsCard />
                </Col>
            </Row>
        </>
    );
};




export default SavedJobsPage

