import environment from '../environments/environment';
import { selectOptionValue } from '../utils/utility.interface';
//import "./http-services/fetch-intercept.service";
const baseUrl = environment.baseUrl + '/api/v1';
const serviceoBaseUrl = environment.serviceoBaseurl;
export const fetchSelectLookupList = async (filter: string): Promise<selectOptionValue[]> => {
    const filterObj = JSON.parse(filter);
    let apiName: any;
    let keyName = '_id';
    let keyValue = '_id';
    let keyLable = 'title';
    switch (filterObj.controlName) {
        case 'language':
            apiName = 'langauge/list';
            break;
        case 'employment':
            apiName = 'organization/list';
            break;
        case 'skillsAndExp':
            apiName = 'skill/list';
            break;
        case 'education':
            apiName = 'qualification/list';
            break;
        case 'certification':
            apiName = 'certification/list';
            break;
        case 'skill':
            apiName = 'skill/list';
            keyValue = 'id';
            keyName = 'id';
            keyLable = 'name';
            break;
        case 'country':
            apiName = 'country/list';
            keyValue = 'sfdcId';
            break;   
        case 'institute':
            apiName = 'education/list';
            break;
            
             
    }
    const apiUrl = `${baseUrl}/${apiName}?name=${filterObj.title}`;
    let resData = []
    return fetch(apiUrl)
        .then(response => response.json())
        .then(body => {
            resData = body.data.map((item: any) => ({ key: item.code ? item.code : item[keyName], label: item[keyLable], value: item[keyValue] }))
            if(!(resData && resData.length)) {
               resData.push({ key: `other-${filterObj.controlName}`, label: 'other', value: 'other' })
            }
            return resData;
        }

        );
}

export const fetchLocationList = async (filter: string): Promise<any[]> => {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${filter}&key=AIzaSyD6cyDKRX89OoENcMcx7ueBWNTnb_KeRCM`
    // `${baseUrl}/${apiName}?name=${filterObj.title}`;
    return fetch(apiUrl)
        .then(response => response.json())
        .then(body =>
            body.results.map(
                (item: any) => ({ key: item.place_id, label: item.formatted_address, value: item.formatted_address }),
            ),
        );
}

export const fetchSelectStateLookupList = async (filter: any) => {
    let apiName: any;
    apiName = 'state/list';
    const apiUrl = `${baseUrl}/${apiName}?country_id=${filter.value}`;
    return fetch(apiUrl)
        .then(response => response.json())
        .then(body =>
            body.data.map(
                (item: any) => ({ key: item._id, label: item.title, value: item.sfdcId }),
            ),
        );
}
/**
 * Get All SliderData
 * @param pageSize 
 * @param pageNo 
 * @returns 
 */
export const getEducationAndSkill = (filter: string): Promise<any> => {
    const filterObj = JSON.parse(filter);
    const apiName = ['education/list', 'skill/list']
    const apiUrl: any = [];

    apiName.forEach(name => {
        apiUrl.push(fetch(`${baseUrl}/${name}?name=${filterObj.title}`))
    })
    return Promise.all(apiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return {
            education: (promiseData[0].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
            skill: (promiseData[1].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),

        }
    });
}

/**
 * Get All SliderData
 * @param pageSize 
 * @param pageNo 
 * @returns 
 */
export const getclientStepData = (pageSize: number = 100, pageNo: Number = 0): Promise<any> => {
    const apiName = ['education', 'experience', 'knowledge']
    const apiUrl: any = [];

    apiName.forEach(name => {
        apiUrl.push(fetch(`${baseUrl}/jd/${name}?pageSize=${pageSize}&page=${pageNo}`))
    })
    return Promise.all(apiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return {
            education: (promiseData[0].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
            experience: (promiseData[1].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
            knowledge: (promiseData[2].data || []).map((obj: any,) => ({ key: obj._id, value: obj._id, label: obj.title })),
        }
    })
}


/**
 * Get All SliderData
 * @param pageSize 
 * @param pageNo 
 * @returns 
 */
export const getRoleStepData = (pageSize: number = 100, pageNo: Number = 0): Promise<any> => {
    const apiName = ['talenttype']
    const apiUrl: any = [];

    apiName.forEach(name => {
        apiUrl.push(fetch(`${baseUrl}/jd/${name}?pageSize=${pageSize}&page=${pageNo}`))
    })
    return Promise.all(apiUrl).then(function (responses) {
        return Promise.all(responses.map(function (response) { return response.json() }));
    }).then(function (promiseData) {
        return {
            talenttype: (promiseData[0].data || []).map((obj: any,) => ({ key: obj.code, value: obj._id, label:`${obj.code} - ${obj.title}` })),
            talenttypeObj: promiseData[0].data
        }
    })
}

export const updateSingleCandidateProfile = (personId: any, id: string, data: any , type: any): Promise<any> => {
    const apiUrl = `${baseUrl}/updateSingleCandidateProfile/` + personId;
    let dataObj: any = {
        educations:[],
        employment_history:[],
        skills:[],
        certifications:[],
        languages:[],
        operation:'add',
        type:type
    };
    if(id){
        dataObj['id'] = id;
        dataObj['operation'] = 'edit';
    }
    if(type==='Education'){
        if (data['education']) {
            data['education'].forEach((element: any) => {
                dataObj.educations.push(prepairArrayObject(element,'education'));
            });
        }
    }
    if(type==='Experience'){
        if (data['employment']) {
            data['employment'].forEach((element: any) => {
                delete element['employment_types'];
                dataObj.employment_history.push(prepairArrayObject(element,'employment'));
            });
        }
    }
    if(type==='Skill'){
        if (data['skill']) {
            data['skill'].forEach((element: any) => {
                dataObj.skills.push(prepairArrayObject(element,'skill'));
            });
        }
    }
    if(type==='Certification'){
        if (data['certification']) {
            data['certification'].forEach((element: any) => {
                dataObj.certifications.push(prepairArrayObject(element,'certification'));
            });
        }
    }
    if (type === 'Language') {
        if (data['language']) {
            data['language'].forEach((element: any) => {
                dataObj.languages.push(prepairArrayObject(element,'language'));
            });
        }
    }
    
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
}

export const updateRegistrationProfile = (profileId: string, postData: any): Promise<any> => {
    const apiUrl = `${baseUrl}/candidateProfileUpdate/` + profileId;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}

export const updateProfile = (profileId: string, data: any , steps: number): Promise<any> => {
    const apiUrl = `${baseUrl}/candidateProfileUpdate/` + profileId;
    let postData: any = prepairProfileData(data, steps);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}
const prepairProfileData = (data: any, steps: number) => {
    switch (steps) {
        case 0:
            return prepairBasicInfo(data);
        case 1:
            return prepairEducationAndCertification(data);
        case 2:
            return prepairEmploymentSkills(data);
        case 3:
             return prepairWorkPreferences(data);
    }
}
const prepairBasicInfo = (data:any) => {
    console.log('prepairBasicInfo::',data)
    let postData: any = {
        personBasicInfo: {},
        languages:[]
    };
    if (data['full_name']) {
        postData['personBasicInfo']['full_name'] = data['full_name'];
    }
    // if (data['email']) { No need to update Email
    //     postData['personBasicInfo']['email'] = data['email'];
    // }
    if (data['phone']) {
        postData['personBasicInfo']['phone'] = data['phone'];
    }
    if (data['gender']) {
        postData['personBasicInfo']['gender'] = data['gender'];
    }
    if (data['language']) {
        data['language'].forEach((element: any) => {
            postData.languages.push(prepairArrayObject(element,'language'));
        });
    }
    if (data['personCountry'] || data['personState'] || data['personCity'] || data['personZip']) {
        postData['address'] = {
            country: data['personCountry'] || '',
            state: data['personState'] || '',
            city: data['personCity'] || '',
            zip: data['personZip'] || ''
        }
        delete data['personCountry'];
        delete data['personState'];
        delete data['personCity'];
        delete data['personZip'];
    }
    return postData;
}
const prepairEducationAndCertification = (data:any) =>{
    let postData: any = {
        educations: [],
        certifications:[]
    };
    if (data['education']) {
        data['education'].forEach((element: any) => {
            postData.educations.push(prepairInstitutCertificatiionArrayObject(element,'education'));
        });
    }
    if (data['certification']) {
        data['certification'].forEach((element: any) => {
            postData.certifications.push(prepairInstitutCertificatiionArrayObject(element,'certification'));
        });
    }
    return postData;
}
const prepairEmploymentSkills = (data:any) => {
    let postData: any = {
        employment_history: [],
        skills:[]
    };
    if (data['employment']) {
        data['employment'].forEach((element: any) => {
            postData.employment_history.push(prepairArrayObject(element,'employment'));
        });
    }
    if (data['skill']) {
        data['skill'].forEach((element: any) => {
            postData.skills.push(prepairArrayObject(element,'skill'));
        });
    }
    return postData;
}
const prepairWorkPreferences = (data:any) => {
    if(data['preffred_job_type']){
        data['preffred_job_type'] = Array.isArray(data['preffred_job_type']) ? data['preffred_job_type'].join(',') : data['preffred_job_type'];
    }
    let postData: any = {
        job_preference: data
    };
    return postData;
}

const prepairArrayObject = (element: any,key: any) => {
    const title = element['other'] ? element['other'] : element[key]['label'];
    delete element['other'];
    return { ...element, ...{ id: element[key]['value'] || '', title: title || '' } }
}
const prepairInstitutCertificatiionArrayObject = (element: any,key: any) => {
    let title = element[key]['label'];
    if(element && element.institute && element['other']) {
        element.institute['label'] = element['other'];
    } else if (element && element.title && element.title.label && element['other']) {
        element.title['label'] = element['other'];
        title = element['other'];
    } 
    delete element['other'];
    return { ...element, ...{ id: element[key]['value'] || '', title: title || '' } }
}



export const deleteRecordById = (id:string,childId:string,type:string,sfdcId:string) => {
    const _that = this;
    const apiUrl = `${baseUrl}/candidateProfileDelete/${type}/${id}/${childId}/${sfdcId}`;
    let modelName = ''
    if (type === 'Education') {
        modelName = 'EducationHistory';
      }
      if (type === 'Experience') {
         modelName = 'EmploymentHistory'
      }
      if (type === 'Skill') {
        modelName = 'JobSkills'
      }   
    return fetch(apiUrl, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json'
        }})
        .then((resp) => resp.json())
        .then((data: any) => {
            
        //Education,Experience,Skill,Certification,Language
        if(modelName && sfdcId!='NA'){
            deleteServiceoRecord(modelName,sfdcId);
        }
        return data;
    });
}
export const updateAboutProfile = (profileId: string, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/candidateProfileUpdate/` + profileId;
    let postData: any = data;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}
export const profileComplete = (id: string,oldData:any, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/candidateProfile/completeness/` + id;
    let postData: any = prepareProfileCompleteNess(id,oldData,data);
    if (postData) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData)
        };
        return fetch(apiUrl, requestOptions).then((resp) => resp.json())
            .then(response => response.data);
    }else{
        return postData
    }
}
const prepareProfileCompleteNess = (id:string, oldData:any, data: any) => {
    let profileCompletenessPer = oldData['completeness_percentage'];
    profileCompletenessPer = profileCompletenessPer + data['weightage'];
    oldData['completeness_percentage'] = profileCompletenessPer;
    let dataObj = {
        ...data,
        is_complete: true,
        candidate_id: id
    };
    const finalData = {
        profile_completeness: dataObj,
        completeness_percentage: oldData['completeness_percentage'] 
    }
    let profileCompleteness = oldData['profile_completeness'];
    //check if already added
    const filtered = profileCompleteness.filter((e: any) => e.category === data['category']);
    if (filtered.length === 0) {
        return finalData;
    } else {
        return false;
    }
}
export const fetchCountryCodeList = async (filter: string): Promise<any[]> => {
    const apiUrl = `${baseUrl}/country/all`;
    return fetch(apiUrl)
        .then(response => response.json())
        .then(body =>
            body.data.map(
                (item: any) => ({ label: item.country_code, value: item.country_code }),
            ),
        );
}
export const fetchEducationDegreeList = async (): Promise<any[]> => {
    const apiUrl = `${baseUrl}/qualification/list`;
    return fetch(apiUrl)
        .then(response => response.json())
        .then(body =>
            body.data.map(
                (item: any) => ({ label: item.title, value: item.title }),
            ),
        );
}
export const getCadidateResume = async (person_id: string) : Promise<any[]> => {
    const apiUrl = `${baseUrl}/candidate-resume/`+person_id;
    return fetch(apiUrl)
        .then(response => response.json())
        .then(body =>
            body.data.map(
                (item: any) => ({ label: item.fileMeta ? item.fileMeta.originalname : item.url, value:item.url }),
            ),
        );
}

export const applyJob = async (data: any) : Promise<any[]> => {
    const apiUrl = `${baseUrl}/jobApplication/create`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
}
export const fetchapplications = async (data: any) : Promise<any[]> => {    
    const apiUrl = `${baseUrl}/getApplications`;
    let postData: any = data;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data.map(
            (item: any) => (item.job_order_id)),
        );
}

export const fetchAllApplicationsById = async (data: any) : Promise<any[]> => {   
    const apiUrl = `${baseUrl}/getApplications`;
    let postData: any = data;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
}


export const getQuestionList = async (questionSet: string): Promise<any[]> => {
    const whereObj = {
        where: {
            Question_Set__c: questionSet
        },
        include:[
            {
              relation:'questionSet'
            },
            {
              relation: 'answer',
              scope: {
                fields: ['sfdcId', 'Question__c', 'Score__c','Answer__c'],
                order: 'Order__c asc'
              }
            }
        ],
        order: 'Order__c asc'
        
    };
    const requestOptions = {
        method: 'GET',
        headers: {
           'Content-Type': 'application/json',
            filter: JSON.stringify(whereObj)
        },
    };
    let URL = serviceoBaseUrl + '/Questions';
    return fetch(URL, requestOptions).then((resp) => resp.json())
        .then(response => response)
        .then(body =>
            body.map(
                (item: any,index:any) => ({...item , ...{ key: (index+1),sfdcId: item['sfdcId'], label: (item['Rich_Text_Question__c']) ? item['Rich_Text_Question__c'] : (item['Question__c']) ? item['Question__c'] : item['Name'], answer: formatData(item.answer),isText:(item.IsTxt__c) ? true :false}}),
            ),
        );
}
export const saveCAQ = async(postData: any) : Promise<any[]> =>{
    const apiUrl = `${baseUrl}/JobCaq/create`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
    //let idToken = localStorage.getItem('idToken');
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         //Authorization: `Bearer ${idToken}`
    //     },
    //     body: JSON.stringify({data:finalObj})
    // };
    // let URL = serviceoBaseUrl + '/JobPrescreens/CaqAns';
    // return fetch(URL, requestOptions).then((resp) => resp.json())
    //     .then(response => response);
}
export const validatePasscode = async(data: any) : Promise<any[]> => {
    const apiUrl = `${baseUrl}/passcode/validate`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
}

export const updateJobApplication = async(data: any,applicationId:any) : Promise<any[]> => {
    const apiUrl = `${baseUrl}/jobApplication/updateCAQStatus/`+applicationId;
    let postData: any = data;
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
}

export const checkCAQSubmitted = async(sfdcId: any) : Promise<any[]> => {
    const apiUrl = `${baseUrl}/jobApplication/caqInfo/`+sfdcId;
    const requestOptions = {
        method: 'GET'
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);
}

function formatData(answerOption:any){  
    let options:any = [];
    answerOption.forEach((element:any)=>{
        options.push({
            key: element.sfdcId, label: element.Answer__c, value: element.Answer__c,
            score: element.Score__c
        })
    })
    return options
}
function deleteServiceoRecord(modelName:string,sfdcId:string){
    let URL = serviceoBaseUrl + '/EmploymentHistories/deleteByModel';
    let idToken = localStorage.getItem('accessJwtToken');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            //'Authorization':`Bearer ${idToken}`
        },
        body: JSON.stringify({modelName:modelName,sfdcId:sfdcId})
    };
    return fetch(URL, requestOptions).then((resp) => resp.json())
        .then(response => response);
} 