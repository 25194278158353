import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment'
export const asDate = (dateAsString: string) => {
    var splitFields = dateAsString.split('/');
    return new Date(
        Number.parseInt(splitFields[2]),
        Number.parseInt(splitFields[1]) - 1,
        Number.parseInt(splitFields[0])
    );
}
export const removeNullUndefined = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));


export const dateFormate = (date: any, formate='MM/DD/YYYY') => {
  return moment(date).format(formate); //hh:mm A
}
export const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      var dateAsString = dateFormate(cellValue, 'DD/MM/YYYY');
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('/');
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])            
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

export const filterParams = {
    comparator: (a: string, b: string) => {
      var valA = parseInt(a);
      var valB = parseInt(b);
      if (valA === valB) return 0;
      return valA > valB ? 1 : -1;
    },
  };

  export const currentDate = (date: any) => {
    return moment(date).isSame(moment(), 'day');
  }
  export const dateFormatter = (params: ValueFormatterParams) => {
    return (params) ? dateFormate(params, 'MMM YYYY'): '';
}
