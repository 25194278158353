export const authLoginFooterText = () => {
   return { text: 'Not A Member yet?', link: 'Register', url: '/register' }
}

export const authSingupFooterText = () => {
   return { text: 'Already have an Account?', link: 'Log In', url: '/login' }
}

export const experienceValue = [
   { min: '01', max: '05', value: '01-05' },
   { min: '05', max: '10', value: '05-10' },
   { min: '10', max: '15', value: '10-15' },
   { min: '15', max: '20', value: '15-20' },
   { min: '20', max: 'More', value: '20-more' }
]

export const jobSortItem = [
   { key: 'Most Recent', label: 'Most Recent' },
   { key: 'High priority jobs', label: 'High priority jobs' },
   { key: 'Popular jobs', label: 'Popular jobs' },
   // { key: 'Nearby jobs', label: 'Nearby jobs' },
]

export const jobSortOption = [
   { value: 'High priority jobs', label: 'High priority jobs' },
   { value: 'Popular jobs', label: 'Popular jobs' },
   { value: 'Recommended jobs first', label: 'Recommended jobs first' },
   // { value: 'Nearby jobs', label: 'Nearby jobs' },
]

export const jobTypeOptions = [
   { label: 'Contract', value: 'Contract' },
   { label: 'Full Time', value: 'Full Time' },
   { label: 'Intern', value: 'Intern' },
   { label: 'Part Time', value: 'Part Time' },
   { label: 'Part Time Benefited', value: 'Part Time Benefited' },
   { label: 'Per Diem', value: 'Per Diem' }
];

export const distanceMilesOptions = [
   { label: '10 Miles', value: '10' },
   { label: '20 Miles', value: '20' },
   { label: '50 Miles', value: '50' },
   { label: '100 Miles', value: '100' },
   { label: '200 Miles', value: '200' }
];

export const jobFreshnesOptions = [
   { label: 'Current Week', value: 'Current Week' },
   { label: 'Last Week', value: 'Last Week' },
   { label: 'Last Month', value: 'Last Month' },
   { label: 'Last Quarter', value: 'Last Quarter' }
];

export const qualificationOptions = [
   { label: 'MCA', value: 'MCA' },
   { label: 'B.Tech', value: 'B.Tech' }
];

export const remoteOptions = [
   { label: 'Yes, Remote Work', value: 'Yes, Remote Work' },
   { label: 'No, Remote Works', value: 'No, Remote Work' },
];


export const profileStepOptions = [
   {
      title: 'Personal Information',
   },
   {
      title: 'Education',
   },
   {
      title: 'Experience',
   },
   {
      title: 'Work Preferences',
   },
];

/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
   required: '${label} is required!',
   types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
   },
   number: {
      range: '${label} must be between ${min} and ${max}',
   },
};

export const workAuthrizationType = [
   { label: 'Normal', value: 'Normal' },
   { label: 'Full Time', value: 'Full Time' },
   { label: 'Freelance', value: 'Freelance' }
];
export const yourPreffredJobType = [
   { label: 'Part Time', value: 'Part Time' },
   { label: 'Full Time', value: 'Full Time' },
   { label: '4 hours or less per day', value: '4 hours or less per day' },
   { label: 'Only Weekends', value: 'Only Weekends' },
];
export const employmentType = [
   { label: 'EOR', value: 'EOR' },
   { label: 'Vendor', value: 'Vendor' },
   { label: 'Internal', value: 'Internal' },
   { label: 'W2', value: 'W2' },
   { label: '1099', value: '1099' },
   { label: 'C2C', value: 'C2C' },
];

export const skillLevel = {
   '1': 'Fresher',
   '3': {
      style: {
         color: 'orange',
         fontWeight: 400
      },
      label: 'Medium',
   },
   '5': {
      style: {
         color: 'green',
         fontWeight: 600
      },
      label: 'Expertise',
   },
};

export const timeZoneOptions = [
   {
      value: '(GMT -12:00) Eniwetok, Kwajalein',
      label: '(GMT -12:00) Eniwetok, Kwajalein'
   },
   {
      value: '(GMT -11:00) Midway Island, Samoa',
      label: '(GMT -11:00) Midway Island, Samoa'
   },
   {
      value: '(GMT -10:00) Hawaii',
      label: '(GMT -10:00) Hawaii'
   },
   {
      value: '(GMT -9:00) Alaska',
      label: '(GMT -9:00) Alaska'
   },
   {
      value: '(GMT -8:00) Pacific Time (US & Canada)',
      label: '(GMT -8:00) Pacific Time (US & Canada)'
   },
   {
      value: '(GMT -7:00) Mountain Time (US & Canada)',
      label: '(GMT -7:00) Mountain Time (US & Canada)'
   },
   {
      value: '(GMT -6:00) Central Time (US & Canada), Mexico City',
      label: '(GMT -6:00) Central Time (US & Canada), Mexico City'
   },
   {
      value: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
      label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima'
   },
   {
      value: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
      label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'
   },
   {
      value: '(GMT -3:30) Newfoundland',
      label: '(GMT -3:30) Newfoundland'
   },
   {
      value: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
      label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown'
   },
   {
      value: '(GMT -2:00) Mid-Atlantic',
      label: '(GMT -2:00) Mid-Atlantic'
   },
   {
      value: '(GMT -1:00) Azores, Cape Verde Islands',
      label: '(GMT -1:00) Azores, Cape Verde Islands'
   },
   {
      value: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
      label: '(GMT) Western Europe Time, London, Lisbon, Casablanca'
   },
   {
      value: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris',
      label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris'
   },
   {
      value: '(GMT +2:00) Kaliningrad, South Africa',
      label: '(GMT +2:00) Kaliningrad, South Africa'
   },
   {
      value: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
      label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg'
   },
   {
      value: '(GMT +3:30) Tehran',
      label: '(GMT +3:30) Tehran'
   },
   {
      value: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
      label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'
   },
   {
      value: '(GMT +4:30) Kabul',
      label: '(GMT +4:30) Kabul'
   },
   {
      value: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
      label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'
   },
   {
      value: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
      label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
   },
   {
      value: '(GMT +5:45) Kathmandu',
      label: '(GMT +5:45) Kathmandu'
   },
   {
      value: '(GMT +6:00) Almaty, Dhaka, Colombo',
      label: '(GMT +6:00) Almaty, Dhaka, Colombo'
   },
   {
      value: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
      label: '(GMT +7:00) Bangkok, Hanoi, Jakarta'
   },
   {
      value: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
      label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'
   },
   {
      value: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
      label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'
   },
   {
      value: '(GMT +9:30) Adelaide, Darwin',
      label: '(GMT +9:30) Adelaide, Darwin'
   },
   {
      value: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
      label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok'
   },
   {
      value: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
      label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'
   },
   {
      value: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
      label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'
   }
];
export const noticePeriod = [
   { label: 'Immediate', value: 'Immediate' },
   { label: 'Week', value: 'Week' },
   { label: '15 days', value: '15 days' },
   { label: '30 days', value: '30 days' },
   { label: '45 days', value: '45 days' },
   { label: '90 days', value: '90 days' }
];
export const salaryTypes = [
   { label: 'Annually', value: 'Annually' },
   { label: 'Monthly', value: 'Monthly' }
];
export const weightageList = {
   'Registration': {
      category: 'Registration', weightage: 15
   },
   'PersonalInformation': {
      category: 'Personal Information', weightage: 10
   },
   'Education': {
      category: 'Education', weightage: 20
   },
   'Experience': {
      category: 'Experience', weightage: 30
   },
   'WorkPreferences': {
      category: 'Work Preferences', weightage: 10
   },
   'ProfilePictureUpload': {
      category: 'Profile Picture Upload', weightage: 5
   },
   'ResumeUpload': {
      category: 'Resume Upload', weightage: 10
   }
};


export const fileTypeAllow = {
   resume: '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
   image: 'image/*',
   resumeSize: 20, // 20MB
   imageSize: 10, // 10MB
}

export const jobFunctionType = [   
   { label: "Software Development", value: "Software Development" },
   { label: "Desktop Engineer", value: "Desktop Engineer" },
   { label: "Hardware Engineer", value: "Hardware Engineer" },
   { label: "Network Engineer", value: "Network Engineer" },
   { label: "Quality Assurance", value: "Quality Assurance" },   
   { label: "Engineering", value: "Engineering" },
   { label: "IT Support", value: "IT Support" },
   { label: "Operations", value: "Operations" },
   { label: "Marketing", value: "Marketing" }, 
   { label: "Finance", value: "Finance" },
   { label: "HR", value: "HR" },
   { label: "Business Development", value: "Business Development" },
   { label: "Project Analyst", value: "Project Analyst" },
   { label: "Incident Management", value: "Incident Management" },    
   { label: "Program & Product Management", value: "Program & Product Management" },
   { label: "Research", value: "Research" },
   { label: "Sales", value: "Sales" }, 
   { label: "Assembler", value: "Assembler" },
   { label: "WareHouse / Store", value: "WareHouse Store" }, 
   { label: "Production", value: "Production" },
   { label: "Logistics", value: "Logistics" },
   { label: "Others", value: "Others" },
];

export const ironStaticPageLinks = [
   { label: "OEM Hardware Services", value: "https://www.ironsystems.com/services" },
   { label: "Technology Services", value: "https://ironserviceglobal.com/" },
   { label: "Company", value: "https://www.ironsystems.com/company/about-ironglobal" }
]
 export const languageProficiency = [
   { label: "A2 (Elementary Proficiency)", value: "Elementary" },
   { label: "B1 (Intermediate/Limited Working Proficiency)", value: "Medium" },
   { label: "B2 (Upper-Intermediate/Professional Working Proficiency)", value: "Professional" },
   { label: "C2 (Proficiency/Master/Native)", value: "Native" }
]