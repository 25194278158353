import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Layout, Skeleton } from "antd";
import ProtectedRoute from "./ProtectedRoute";
import { connect } from "react-redux";
import PublicRoute from "./PublicRoute";
import Error404Pages from "../pages/error-pages/Error404Page";
import SavedJobsPage from "../pages/SavedJobs";


const PrivateRoute: React.FC<any> = ({ isLoggedIn }) => {
  const Login = lazy(() => import("../components/auth/Login"));
  const OAuthAuthenticate = lazy(() => import("../components/auth/OauthAuthenticate"));
  const StytchRegister = lazy(() => import("../components/auth/StytchRegister"));
  const JobSearchPage = lazy(() => import("../pages/job-Search-Pages/JobSearchPage"));
  const ProfilePages = lazy(() => import("../pages/profile-pages/ProfilePage"));
  const EditProfilePage = lazy(() => import("../pages/profile-pages/edit-profile-pages/EditProfilePage"));
  const ProfileHomePages = lazy(() => import("../pages/profile-pages/ProfileHomePage"));
  const PreviewProfilePage = lazy(() => import("../pages/profile-pages/edit-profile-pages/PreviewProfilePage"));
  const ApplicationPages = lazy(() => import("../pages/applications/ApplicationPage"));
  const ApplyJobCAQPage = lazy(() => import("../pages/apply-jobs/ApplyJobCAQPage"));
  const PublicCAQPage = lazy(() => import("../pages/apply-jobs/PublicCAQPage"));
  const PublicApplyJobPage = lazy(() => import("../pages/apply-jobs/PublicApplyJobPage"));
  const NotificationPage = lazy(() => import("../pages/notifications/NotificationPage"));
  const layoutStyle: React.CSSProperties = {
    padding: "0",
    backgroundColor: "#fff",
  };
  const loginStyle: React.CSSProperties = {
    padding: "0"
  };

  return (
    <Layout style={isLoggedIn ? layoutStyle : loginStyle}>
      <Suspense fallback={<Skeleton active />}>
        <Routes>
          {/*Public Route   */}
          <Route path="/" element={isLoggedIn ? <ProtectedRoute component={<ProfileHomePages />} /> : <Login />} />
          <Route path="/login" element={<PublicRoute component={<Login />} />} />
          <Route path="/authenticate" element={<PublicRoute component={<OAuthAuthenticate />} />} />
          {/* <Route path="/forgot-password" element={<PublicRoute component={<ForgotPassword />}/>} /> */}
          {/* <Route path="/reset-password/:id" element={<ResetPassword />} /> */}
          <Route path="/register" element={<PublicRoute component={<StytchRegister />} />} />
          {/* <Route path="/register-info/:id" element={<RegisterMoreInfo />} /> */}
          <Route path="/public/caq/:applicationId/:questionSetId" element={<PublicCAQPage />} />
          <Route path="/job/:jobId" element={<PublicApplyJobPage />} />
          {/*Protected Route   */}
          <Route path="/job-search" element={<ProtectedRoute component={<JobSearchPage />} />} />
          {/* <Route path="/job/:jobId" element={<ProtectedRoute component={<PublicApplyJobPage />}/>} /> */}
          <Route path="/profile" element={<ProtectedRoute component={<ProfilePages />} />} />
          <Route path="/home" element={<ProtectedRoute component={<ProfileHomePages />} />} />
          <Route path="/edit-profile" element={<ProtectedRoute component={<EditProfilePage />} />} />
          <Route path="/application" element={<ProtectedRoute component={<ApplicationPages />} />} />
          <Route path="/preview-save" element={<ProtectedRoute component={<PreviewProfilePage />} />} />
          <Route path="/person-caq/:applicationId/:questionSetId" element={<ProtectedRoute component={<ApplyJobCAQPage />} />} />
          <Route path="/notification" element={<ProtectedRoute component={<NotificationPage />} />} />
          <Route path="*" element={<Error404Pages />} />
          <Route path="/saved-jobs" element={<SavedJobsPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};


const mapStateToProps = (state: any) => {
  return { isLoggedIn: state.reducer.auth.isLoggedIn };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

