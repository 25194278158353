import { SET_SELECTED_JOB, SET_APPLY_JOB, SET_JOBS } from "./types";
import { fetchjobs } from "../../services/elastic.service";

export const setSelectedJob = (payload: unknown) => ({
  type: SET_SELECTED_JOB,
  payload: payload,
});
export const setApplyJob = (payload: unknown) => ({
  type: SET_APPLY_JOB,
  payload: payload,
});

export const getJobs = (payload: unknown) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    fetchjobs(payload).then(res => {
      const jobs = (res && res.length) ? res[0].hits : [];
      dispatch({ type: SET_JOBS, payload: jobs });
      resolve(jobs);
    }).catch(err => {
      reject(err);
    })
  });
};

