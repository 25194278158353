import axios from "axios";
import environment from '../environments/environment';
const baseUrl = environment.baseUrl + '/api/v1';

export const fetchjobs = async (filter: any) => {
    const apiUrl = `${baseUrl}/job/search`;
    if (filter && !filter.search_by_id) {
        filter['search_by_id'] = '1223' ;
    }

    return axios.post(apiUrl, filter).then(res => { return res.data && res.data.data.length ? res.data.data[0].hits : [] });
}

export const fetchjobById = async (id: string) => {
    const apiUrl = `${baseUrl}/job/${id}`;   
    return axios.get(apiUrl).then(res => { return res.data && res.data.data.length ? res.data.data[0].hits : [] });
}

export const fetchjobByJobNum = async (jobNo: string) => {
    const apiUrl = `${baseUrl}/jobNum/${jobNo}`;   
    return axios.get(apiUrl).then(res => { return res.data && res.data.data.length ? res.data.data[0].hits : [] });
}
