import dayjs from "dayjs";
import environment from "../environments/environment";
const fileBaseUrl = environment.accessDocumentUrl;
export const sum = (a:number, b:number) => {
    return a + b;
};


export const sortbyKeyAsc = (dataSource: any, key: string) => {
    return dataSource.sort((first:any, second: any) => 0 - (first[key] > second[key] ? -1 : 1));
} 

export const sortbyKeyDesc = (dataSource: any, key: string) => {
    return dataSource.sort((first:any, second: any) => 0 - (first[key] > second[key] ? 1 : -1));
} 


export const containsFilter = (value: string,filterText: string) => {
    const valueLowerCase = value ? value.toString().toLowerCase() : '';
    const filterTextLowerCase = filterText ? filterText.toLowerCase() : '';
    return valueLowerCase.indexOf(filterTextLowerCase) >= 0; //contains
}
export const selectionValueDesc = (data: any, type: string) =>{
    return (data || []).map((e: any) => (ddlValueDesc(e,type)));
}

export  const ddlValueDesc = (e: any,type: string) => {    
    let values:any = {};
    const returnVal = {[type] :{
        label:e.title,
        value: e.id, 
        key: e.id, 
        _id: e._id,
        sfdcId:e.sfdcId
    }};
    if(type === 'education'){
        values = {
            ...returnVal, description: e.description, id: e.id,duration_type: e.duration_type,institute: e.institute
        }
        if(e.year_from){
            values['year_from'] = dayjs(new Date(e.year_from));
        }
        if(e.year_to){
            values['year_to'] = dayjs(new Date(e.year_to));
        }
    }
    if(type === 'employment'){
        values = {
            ...returnVal, designation: e.designation, location: e.location, employment_type: e.employment_type, currently_working: e.currently_working, responsibility: e.responsibility, 
            id: e.id
        }
        if(e.year_from){
            values['year_from'] = dayjs(new Date(e.year_from));
        }
        if(e.year_to){
            values['year_to'] = dayjs(new Date(e.year_to));
        }
    }
    if(type === 'certification'){
        values = {
            ...returnVal, description: e.description, id: e.id
        }
        if(e.year_from){
            values['year_from'] = dayjs(new Date(e.year_from));
        }
        if(e.year_to){
            values['year_to'] = dayjs(new Date(e.year_to));
        }

        if(e.valid_till){
            values['valid_till'] = (e.valid_till=='No expiry') ? 'No expiry':dayjs(new Date(e.valid_till)).format('YYYY');
        }
    }
    if(type === 'skill'){
        values = {
            ...returnVal, level: e.level, id: e.id
        }
    }
    if(type === 'language'){
        values = {
            ...returnVal, fluency_level: e.fluency_level, id: e.id
        }
    }
    return values;
}

export const prepairPictureUrl = (pictureUrl: string) => {
    // if (pictureUrl && pictureUrl.indexOf("http") !== -1) {
    //     console.log(pictureUrl)
    //     return fileBaseUrl + pictureUrl;
    // } else 
    
    if(pictureUrl) {
        return fileBaseUrl + pictureUrl
    } else {
        return 'https://den.serviceo.me/api/v1/Containers/profile-images/download/5c9e11c7-b95d-486b-8740-f413b35430cc.png'
    }
}

export const phoneRegexValidation = (value: any) => {
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    return !(!value || regex.test(value) === false)
}

export const passwordRegexValidation = (value: any) => {
    // Minimum eight characters, at least one letter, one number and one special character:
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/
    return regex.test(value);
}

export const phoneRegexValidationIndia = (value: any) => {
    //digit and +
    const regex = /^((\+91?)|\+)?[7-9][0-9]{9}$/
    return regex.test(value);
}