import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Typography, Button, Space, Tag, Spin, message, Divider } from "antd";
import { connect } from "react-redux";
import { BorderlessTableOutlined, CalendarOutlined, CheckOutlined, ClockCircleOutlined, DollarOutlined, EnvironmentOutlined, GiftOutlined, HomeOutlined } from "@ant-design/icons";
import BtnSpin from "../../components/spin/BtnSpin";
import { setApplyJob } from "../../state/actions/job";
import { dateFormate } from "../../utils/DateUtility";
import { fetchjobById } from "../../services/elastic.service";
import { fetchapplications } from "../../services/common.service";
import ApplyJobModalPage from "../apply-jobs/ApplyJobModalPage";

const { Title, Text, Paragraph } = Typography;
const JobDetailsPages: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const { jobId, user, setApplyJob, selectedJob } = props;
    const [jobDetails, setjobDetails] = useState<any>({});
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [pageLoading, SetPageLoading] = useState<boolean>(true);
    const [appliedJobData, setAppliedJobData] = useState<any>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (jobId) {
            getJobs(jobId);
            getAppliedJobs();
        }
    }, [jobId]);

    const getJobs = (jobId: string) => {
        fetchjobById(jobId).then((res: any) => {
            SetPageLoading(false);
            res = res.hits && res.hits.length ? res.hits[0] : {};
            setjobDetails(res._source);
        }, err => {
            message.error('error! Job not found.')
            SetPageLoading(false);
        })
    }

    const renderHtmlContent = () => {
        return { __html: jobDetails?.Job_Advertisement__c };
    }


    const OnApplyClick = () => {
        setBtnLoading(true);
        if (user && user._id) {
            setIsModalOpen(true);
        } else {
            setApplyJob(selectedJob);
            localStorage.setItem('redirectUrl', `/job/${jobDetails.sfdcId}`);
            navigate('/login', { replace: true });
        }
    }

    const getAppliedJobs = async () => {
        if (user && user._id) {
            const appliedJob = await fetchapplications({ applied_by_candidate: user._id });
            if (appliedJob) {
                setAppliedJobData(appliedJob);
            }
        }
    }

    const updateAppliedJobs = () => {
        getAppliedJobs();
        setIsModalOpen(false);
        setBtnLoading(false);
    }

    const jdStyle = { color: 'rgba(0, 0, 0, 0.7)', fontWeight: 'bold', marginBottom: '1em' }
    const jdTopBg = { background: '#f7f8fa', borderRadius: '10px', padding: '1em', border: '1px solid rgb(240, 240, 240)' }
    return (
        <Fragment>
            {pageLoading
                ? <div className="TextCenter pt-2"> < Spin tip="Loading" key={0} size="small" /> </div >
                : <Fragment>
                    <div style={jdTopBg}>
                        <Row className="jobDetailTop">
                            <Col span={24} className="mb-2 mt-2">
                                <EnvironmentOutlined className="pr-1 pb-3" style={{ fontSize: '1.1em', color: '#64748b' }} />
                                <Text style={{ fontSize: '1.1em', color: '#64748b', fontWeight: '500' }}>{jobDetails?.Location_List_New__c}</Text>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={8}>
                                <label>Job Number</label>
                                <Title level={5} className="mt-0"> {jobDetails?.Job_Number__c}</Title>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8}>
                                <label>Employment Type</label>
                                <Title level={5} className="mt-0">
                                    <ClockCircleOutlined className="pr-1" />
                                    {jobDetails?.Employment_Type_js__c}
                                </Title>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8}>
                                <label>Work Type</label>
                                <Title level={5} className="mt-0">
                                    <Tag className="pr-1" color="cyan">{jobDetails?.Job_Location_Type__c}</Tag>
                                </Title>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} >
                                <label>Job Function</label>
                                <Title level={5} className="mt-0"> {jobDetails?.Job_Function__c} </Title>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8}>
                                <label>Job Posted On</label>
                                <Title level={5} className="mt-0">
                                    <CalendarOutlined className="pr-1" />
                                    {dateFormate(jobDetails?.Date_Posted__c, 'DD MMM, YYYY')}
                                </Title>
                            </Col>
                            {(jobDetails?.Min_Experience__c || jobDetails?.Max_Experience__c) &&
                                <Col xs={12} sm={12} md={12} lg={8}>
                                    <label>Experience</label>
                                    <Title level={5} className="mt-0">
                                        <GiftOutlined className="pr-1" />
                                        {jobDetails?.Min_Experience__c ? `${jobDetails?.Min_Experience__c} -` : ''}  {jobDetails?.Max_Experience__c} Years
                                    </Title>
                                </Col>
                            }
                        </Row>

                        <Row className="mt-2">
                            <Col span={24}>
                                {appliedJobData && appliedJobData.includes(jobDetails.sfdcId) ? (
                                    <Tag color="green"><CheckOutlined />APPLIED</Tag>
                                ) : (
                                    <Button type="primary" onClick={OnApplyClick}>
                                        <BtnSpin size="small" loading={btnLoading} /> Apply Now
                                    </Button>
                                )}
                            </Col>
                        </Row>

                    </div>

                    <Row>
                        <Col span={24}>
                            <Title className="mt-5 mb-1" level={5}>{jobDetails?.Job_Title__c}</Title>
                            <Paragraph>
                                <div dangerouslySetInnerHTML={renderHtmlContent()} ></div>
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title className="mt-5 mb-1" level={5}>Mandatory Skills</Title>
                            <Paragraph>
                                {jobDetails?.Mandatory_skills__c}
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="TextCenter">
                            {appliedJobData && appliedJobData.includes(jobDetails.sfdcId) ? (
                                <Tag color="green" className="applied"><CheckOutlined />APPLIED</Tag>
                            ) : (
                                <Button type="primary" onClick={OnApplyClick}>
                                    <BtnSpin size="small" loading={btnLoading} /> Apply Now
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Fragment>}
            <ApplyJobModalPage key='applyjobModelKey' isModalOpen={isModalOpen} onCloseModal={() => { updateAppliedJobs() }} title={`Job Title: ${jobDetails?.Job_Title__c}`} type='applyJob' jobOrderId={jobDetails?.sfdcId} />
        </Fragment >
    );
};


const mapStateToProps = (state: any) => {
    return {
        selectedJob: state.reducer.job.selectedJob,
        user: state.reducer.auth.user
    };
};

function mapDispatchToProps(dispatch: any) {
    return {
        setApplyJob: (payload: any) => dispatch(setApplyJob(payload))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailsPages);