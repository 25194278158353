import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
export interface IProps {
    loading: boolean,
    size?: 'small' | 'large' | 'default'
}
const BtnSpin: React.FC<IProps> = (props) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading]);

    return (
        <Spin size={props.size ? props.size : 'default'} spinning={loading} className="mr-2"/>         
    )
};

export default BtnSpin;