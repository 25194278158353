import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Drawer, Spin, Typography } from 'antd';
import { find } from '../../../services/universal.service';
import { dateFormate } from '../../../utils/DateUtility';
import JobDetailsPages from '../../../pages/job-Search-Pages/JobDetailsPage';
import { connect } from "react-redux";
import Title from 'antd/es/typography/Title';

const cardBodyStyle: React.CSSProperties = { paddingTop: '0' };

const SaveJobCardPage: React.FC<any> = (props) => {
    const { user } = props
    const [saveJobData, SetSaveJobData] = useState([])
    const [selectedJob, SetSelectedJob] = useState<any>();
    const { innerWidth } = window;
    const [open, setOpen] = useState(false);
    const [loader, SetLoader] = useState(true);
    const [pageNo, setPageNo] = useState(0);
    const [loadMore, setLoadMore] = useState(true);

    const onClick = () => {
        SetLoader(true);
        const newPage = pageNo + 1;
        setPageNo(pageNo + 1);
        getSaveJob(newPage);
    }
    useEffect(() => {
        getSaveJob(0);
    }, []);

    const getSaveJob = async (skip: number) => {
        if (user && user._id) {
            let job: any = await find('saveJob/list', { filter: { save_by_id: user._id }, skip: skip, limit: 4 });
            if (job && job.data && job.data.length < 4) {
                setLoadMore(false);
            }
            job = [...saveJobData, ...job.data];
            SetSaveJobData(job);
            SetLoader(false);
        }
    }

    const OnCardClick = (job: any) => {
        SetSelectedJob(job);
        setOpen(true);
    }

    const closedDrawer = () => {
        setOpen(false);
    };

    const getDrawerWidth = () => {
        return innerWidth - innerWidth / 2 + 100;
    }

    return (
        <Fragment>
            <Card hoverable className="scrollbox lr-card mb-3 prevSearchCard" bodyStyle={cardBodyStyle}>
                <Typography.Title className="mt-0 pl-4 pt-4 pb-4 bg-gray-1 heading-Sidebar" level={4}>Saved Jobs</Typography.Title>
                {saveJobData.map((job: any, index) => {
                    return <div key={index} className="pointer prevSearch" title='View Job' onClick={() => OnCardClick(job)}>
                        <Typography.Text className="AppColor recSearchJob" strong={true}>{job?.job_title} </Typography.Text>
                        <Typography.Text> in {job?.job_company_name}, {job?.job_location} with {job?.job_exprince} EXP. </Typography.Text><br />
                        <Typography.Text className="lr-card-date mt-1 recSearchJob">{dateFormate(job?.execution_at, 'DD MMM YYYY, h:mm a')}</Typography.Text>
                    </div>
                })}
                {loader && <div className="TextCenter pt-2"> <Spin tip="Loading" key={0} size="small" /> </div>}
                {loadMore && <div className='TextCenter pt-5 pb-5'><Button size="small" onClick={onClick} className='pr-3 pl-3'>View More</Button> </div>}
                {!saveJobData.length && <Title level={5} className="mt-0 mb-0 pl-5">Save job not found!</Title>}
            </Card>

            <Drawer title={`Job Title: ${selectedJob?.job_title}`} width={getDrawerWidth()} placement="right" onClose={closedDrawer} open={open} destroyOnClose={true}>
                <JobDetailsPages jobId={selectedJob?.job_id} />
            </Drawer>
        </Fragment>
    )
};

const mapStateToProps = (state: any) => {
    return { user: state.reducer.auth.user };
};

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveJobCardPage);