import axios from "axios";
import environment from '../environments/environment';
const baseUrl = environment.baseUrl + '/api/v1';

export const findAll = async (url: string) => {
    const apiUrl = `${baseUrl}/${url}`;
    return axios.get(apiUrl).then(res => { return res.data ? res.data : [] });
}

export const find = async (url: string, filter:any) => {
    const apiUrl = `${baseUrl}/${url}`;
    return axios.post(apiUrl, filter).then(res => { return res.data ? res.data : [] });
}

export const findById = async (url: string, id: string) => {
    const apiUrl = `${baseUrl}/${url}/${id}`;
    return axios.get(apiUrl).then(res => { return res.data ? res.data : {} });
}

export const Create = async (url: string, data: any) => {
    const apiUrl = `${baseUrl}/${url}`;
    return axios.post(apiUrl, data);
}

export const updateById = async (url: string, id: string, data: any) => {
    const apiUrl = `${baseUrl}/${url}/${id}`;
    return axios.put(apiUrl, data);
}

export const deleteById = async (url: string, id: string) => {
    const apiUrl = `${baseUrl}/${url}/${id}`;
    return axios.delete(apiUrl).then(res => { return res.data ? res.data : [] });
}